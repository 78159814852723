export const fileToBase64Image = async (file: File) =>
  new Promise<string | null>((resolve) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    if (file)
      reader.readAsDataURL(file)
    else
      resolve(null)
  })
